import React, { useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../constants.js';

const VerifyAccount = () => {
  const history = useHistory();
  const [setMsg] = useState('');

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const params  = urlParams.get('email');
    
    const handleSubmit = async () => {
      try {
        await axios.post( API_URL + '/auth/verify-account', {
          email: params
        });
        history.push("/login");
      } catch (error) {
        if (error.response) {
          setMsg(error.response.data.msg);
          history.push("/login");
        }
      }
    }
    
  handleSubmit(params);

  return (
    <></>
  );
};

export default VerifyAccount;