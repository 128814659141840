import React, { useEffect, useState } from "react";
import { Grid, TextField } from '@mui/material';
import { Link, useHistory } from "react-router-dom";
import { makeStyles, Button } from "@material-ui/core";
import { API_URL } from "../constants";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(10),
        backgroundColor: "white",
        "@media (max-width: 662px)": {
            padding: "60px 20px 0px 20px",
        },
        "& .MuiTextField-root": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: "100%",
        },
        "& .MuiButtonBase-root": {
            margin: theme.spacing(2),
        },
    },
    error: {
        color: "red",
    },
    success: {
        color: "green",
    },
    calculation: {
        display: "block",
    },
    calculationBtn: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#1b2854",
        marginBottom: "15px",
        "@media (max-width: 662px)": {
            fontSize: "18px !important",
        },
    },
    customBtn: {
        "@media (max-width: 662px)": {
            height: "42px",
            width: "100% !important",
            textAlign: "center",
            display: "flex",

            margin: 'unset !important',
        },
    },
    BacktoPasswordBtn: {
        "@media (max-width: 662px)": {
            height: "42px",
            width: "100% !important",
            textAlign: "center",
            display: "block",
        },
    },
    tabsectionA: {
        "@media (max-width: 662px)": {
            margin: "5px",
            fontSize: "14px",
        },
    },
}));

const Profile = ({
    msg,
    surnameMsg,
    surname,
    lastNameMsg,
    lastname,
    company,
    email,
    setSurName,
    setLastName,
    setCompany,
    passMsg,
    password,
    confPassword,
    setPassword,
    confPassMsg,
    setConfPassword,
    setConfPassMsg,
    setPassMsg,
    UpdateProfile,
    UpdatePassword
}) => {

    const [userToken, setUserToken] = useState(localStorage.getItem('token'));
    const [userID, setUserId] = useState(localStorage.getItem('user_id'));
    const [subscription, setSubscription] = useState([]);
    const [monthly, setMonthly] = useState(0);
    const [yearly, setYearly] = useState(0);

    const FAQSubscription = async () => {
        try {
            var result = await axios.get(API_URL + '/subscription/' + userID, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (typeof result.data.result != 'undefined') {
                const s = result.data.result;
                setSubscription(s);
                var qty_m = 0;
                var qty_y = 0;
                s.map((item, index) => {
                    if (item.status == 1) {
                        if (item.plan_interval == 'year') {
                            qty_y = parseInt(qty_y) + parseInt(item.quantity);
                        } else {
                            qty_m = parseInt(qty_m) + parseInt(item.quantity);
                        }
                    }
                });
                setMonthly(qty_m);
                setYearly(qty_y);
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        FAQSubscription();
    }, []);

    const classes = useStyles();

    return (<>
        <h1 className={classes.calculationBtn}>Mon compte</h1>
        <Grid container direction="row" spacing={1} key='p-b-1'>
            <Grid item xs={12} md={6} sm={12} key='p-d-4'>
                {msg !== "" ? <p className={classes.error}>{msg}</p> : ""}
                <TextField
                    label="Prénom *"
                    type="text"
                    value={surname}
                    onChange={(e) => { setSurName(e.target.value); }}
                    variant="outlined"
                    className={classes.inputSurname}
                />
                {surnameMsg !== "" ? (
                    <p className={classes.error}>{surnameMsg}</p>
                ) : (
                    ""
                )}
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-d-3'>
                <TextField
                    label="Nom *"
                    value={lastname}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    variant="outlined"
                    className={classes.inputSurname}
                />
                {lastNameMsg !== "" ? (
                    <p className={classes.error}>{lastNameMsg}</p>
                ) : (
                    ""
                )}
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-d-2'>
                <TextField
                    label="Entreprise"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    variant="outlined"
                    className={classes.inputSurname}
                />
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-d-5'>
                <TextField
                    label="Email *"
                    type="email"
                    value={email}
                    disabled={true}
                    variant="outlined"
                    className={classes.inputSurname}
                />
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-d-1'>
                <div>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.customBtn}
                        onClick={UpdateProfile}
                    >
                        Sauvegarder
                    </Button>
                    {/* <Link to="/dashboard" className="is-info mt-4 pull-right"> */}
                    <Link to="/dashboard" className={classes.BacktoPasswordBtn}>
                        Retour à l'accueil
                    </Link>
                </div>
            </Grid>
        </Grid>

        <hr style={{ border: "0.5px solid" }} />

        <h1 className={classes.calculationBtn}>Modifier le mot de passe</h1>
        <Grid container direction="row" spacing={1} key='p-b-2'>
            <Grid item xs={12} md={6} sm={12} key='p-g-3'>
                {msg !== "" ? <p className={classes.error}>{msg}</p> : ""}
                <TextField
                    label="Mot de passe"
                    type="password"
                    value={password}
                    onChange={(e) =>{ 
                        setPassMsg('');
                        setPassword(e.target.value)}
                    }
                    autoComplete="new-password"
                    variant="outlined"
                    className={classes.inputFieldName}
                />
                {passMsg !== "" ? (
                    <p className={classes.error}>{passMsg}</p>
                ) : (
                    ""
                )}
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-g-2'>
                <TextField
                    label="Confirmation du mot de passe"
                    type="password"
                    value={confPassword}
                    onChange={(e) => {
                        setConfPassMsg('');
                        setConfPassword(e.target.value);
                    }}
                    autoComplete="new-password"
                    variant="outlined"
                    className={classes.inputFieldName}
                />
                {confPassMsg !== "" ? (
                    <p className={classes.error}>{confPassMsg}</p>
                ) : (
                    ""
                )}
            </Grid>
            <Grid item xs={12} md={6} sm={12} key='p-g-1'>
                <div className={classes.saveChangePassword}>
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.customBtn}
                        onClick={() => { UpdatePassword(); }}
                    >
                        Sauvegarder
                    </Button>

                </div>
            </Grid>
        </Grid>
    </>)
}


export default Profile;