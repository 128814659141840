import React, { useState, useEffect  } from "react";
import  { Redirect, useHistory } from 'react-router-dom'

import axios from 'axios';
import { API_URL } from '../constants.js';


function Home() {

  const history = useHistory();
  useEffect(() => {
      const isAuth = () => {
        const token = localStorage.getItem('token');
        if (token !== '') {
            const response = axios.get( API_URL + '/users/get-auth-user/' + token, {
            headers: {
                Authorization: `Bearer ${token}`
            }
            }).then((response) => {
                history.push("/dashboard");
            }).catch(error => {
                localStorage.removeItem("token");
                localStorage.removeItem("user_id");
                history.push("/login");
            })
        } else {
            history.push("/login");
        }
      }
      isAuth();
  });
  
  return (
    <div>
      
    </div>
  );

}
export default Home;