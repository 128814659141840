import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { API_URL, FAQ_PURCHASE_MONTHLY, FAQ_PURCHASE_YEARLY, SUPER_ADMIN, SSR_PREFIX } from '../constants';
import { useHistory, useLocation } from "react-router-dom";
import {
    Container, Box,
    Card, CardContent, CardHeader,
    Typography, Button
} from "@material-ui/core";
import { makeStyles } from 'tss-react/mui';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: 'white',
        marginTop: "65px",
        marginBottom: "100px",
        ['& .MuiCardHeader-title']: {
            fontSize: '26px',
            fontWeight: 'bold',
            color: '#1b2854'
        },
        ['& .MuiCardHeader-action']: {
            marginTop: '0px',
            marginRight: '0px'
        }
    },
    title: {
        fontSize: '38px !important',
        fontWeight: 'bold !important',
        color: '#1b2854 !important'
    },
    activeFaqCount: {
        fontWeight: '500',
        color: "#1b2854",
        textAlign: 'center',
        fontSize: '24px'
    },
    activeFaqText: {
        fontWeight: 'bold',
        color: "#3f51b5",
        textAlign: 'center',
        fontSize: '14px'
    },
    mainBlock: {
        padding: '30px 0px 40px'
    },
    buttonGray: {
        color: "#ffffff !important",
        background: '#767676 !important',
        ackground: '#767676 !important',
        marginRight: '5px !important'
    },
    faqDisableRow: {
        background: "rgba(54, 54, 54, 0.22) !important",

    },
    upgrateText: {
        color: '#3f51b5 !important',
        fontStyle: 'italic !important',
        fontWeight: '600 !important',
    }
}));

const Dashboard = ({ handleLoadingToggle, forceUpdate, openPageLoading }) => {
    const count = useRef(0);
    const history = useHistory();
    const location = useLocation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Checkout Success
    /*useEffect(async () => {
        if (urlParams.get('checkout_session_id')) {
            var checkout_session_id = urlParams.get('checkout_session_id');
            console.log(checkout_session_id);
            var utm_campaign = urlParams.get('utm_campaign'); // user_id
            var utm_content = urlParams.get('utm_content'); // customer_id
            await axios.post(API_URL + '/subscription/create', {
                checkoutSessionId: checkout_session_id,
                user_id: utm_campaign,
                email: utm_content
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(result => { console.log('CHECKOUT: ', result) })
                .catch(error => console.error('ERROR: ', error));
        }
    }, []); */

    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(localStorage.getItem('user_id'));
    const [userToken, setUserToken] = useState(localStorage.getItem('token'));
    const [buy_faq_monthly, setBuyFaqMonthly] = useState('');
    const [buy_faq_yearly, setBuyFaqYearly] = useState('');

    const [faqs, setFaqs] = useState([]);
    // Alerts
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const [currentPlan, setCurrentPlan] = useState('FREE');
    const [active_purchase, setTotalPurchase] = useState(0);
    const [active_subscription, setActiveSubscription] = useState(0);
    const [total_created, setTotalCreated] = useState(0);
    const [available, setAvailable] = useState(0);
    const [total_allow_faq, setAllowedFaq] = useState(0);
    const [expiredFaq, setExpiredFaq] = useState(0);
    const [pro_faq_created, setProFaqCreated] = useState(0);


    useEffect(async () => {
        handleLoadingToggle(true);
        try {
            if (userToken !== undefined && userToken !== null && userToken !== '') {
                await axios.get(API_URL + '/users/get-auth-user/' + userToken, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                }).then(function (response) {
                    setUserId(response.data.id);
                });
            } else {
                setUserToken(null);
                setUserId(null);
                history.push("/login");
            }
        } catch (error) {
            console.log(error);
            history.push("/login");
        }

        // if (location.state != undefined && location.state !== '') {
        //     setAlertMessage(location.state);
        //     setSeverity('success');
        //     setIsAlert(true);
        //     window.history.replaceState({}, "");
        // }

    }, []);

    const userData = async () => {
        await axios.get(API_URL + "/users/get-profile/" + userId, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        }).then((response) => {
            var link_params = new URLSearchParams({
                client_reference_id: response.data.customer_id,
                prefilled_email: response.data.email,
                utm_content: response.data.customer_id,
                utm_medium: response.data.email,
                utm_campaign: response.data.id,
            }).toString();
            setBuyFaqMonthly(FAQ_PURCHASE_MONTHLY + '?' + link_params);
            setBuyFaqYearly(FAQ_PURCHASE_YEARLY + '?' + link_params);
            setUser(response.data);
        }).catch((error) => {
            console.log("error", error);
        });

        await axios.get(API_URL + "/users/get-user-dashboard/" + userId, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        }).then((response) => {

            setTotalPurchase(response.data.result.total_purchased);
            setTotalCreated(response.data.result.total_created);
            setCurrentPlan(response.data.result.current_plan);
            setAvailable(response.data.result.available_faq);
            setAllowedFaq(response.data.result.total_allow_faq);
            setActiveSubscription(response.data.result.active_subscription);

        }).catch((error) => {
            console.log(error)
        });
    };

    useEffect(() => {
        if (userToken !== undefined && userToken !== null && userToken !== '') {
            userData();
        }
    }, [count.current]);


    const userFaqData = async () => {
        await axios.get(API_URL + "/users/get-user-dashboard/" + userId, {
            headers: {
                Authorization: `Bearer ${userToken}`,
            },
        }).then((response) => {
            setProFaqCreated(response.data.result.pro_faq_created)
            setActiveSubscription(response.data.result.active_subscription)
            const available_pro_faq = response.data.result.active_subscription - response.data.result.pro_faq_created;
            setAvailable(available_pro_faq);
            // count.current = count.current + 1;
        }).catch((error) => {
            console.log(error)
        });

    };

    useEffect(() => {
        if (userToken !== undefined && userToken !== null && userToken !== '') {
            userFaqData();
        }
    }, [count.current]);

    const faqDataList = async () => {
        await axios.get(API_URL + '/faqs/getAllFaqSubsciption', {
            headers: {
                Authorization: `Bearer ${userToken}`
            }, params: {
                userId: userId
            }
        }).then((response) => {
            setFaqs(response.data);
            handleLoadingToggle(false);
        }).catch((error) => {
            console.log(error);
            handleLoadingToggle(false);
        });

    }
    useEffect(async () => {
        if (userToken !== undefined && userToken !== null && userToken !== '') {
            faqDataList();
        }
    }, [count.current]);


    const handelFaqPlan = async (faqId, plan_type) => {
        handleLoadingToggle(true);
        if (plan_type == 1) {
            var result = await axios.post(API_URL + "/faqs/updateFAQStatusDeactive", {
                faqId: faqId,
                userId: userId,
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            }
            ).then((result) => {
                setAvailable(available + 1);
                forceUpdate();
                handleLoadingToggle(false);
                return result;
            });
            count.current = count.current + 1;
            return result;
        } else if (plan_type == 0) {
            var result = await axios.post(API_URL + "/faqs/updateFAQStatusActive",
                {
                    faqId: faqId,
                    userId: userId,
                },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            ).then((result) => {
                setAvailable(available - 1);
                forceUpdate();
                handleLoadingToggle(false);
                return result;
            });
            count.current = count.current + 1;
            return result;
        }

    }

    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root)}>

            {isAlert ? (
                <Box sx={{ width: '100%' }} >
                    <Snackbar sx={{ width: '100%' }} spacing={2}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={isAlert}
                        autoHideDuration={5000}
                        onClose={() => {
                            setIsAlert(false);
                            setAlertMessage('');
                            setSeverity('');
                        }}>
                        <Alert
                            sx={{ alignItems: 'center' }}
                            severity={severity}
                            action={
                                <IconButton
                                    sx={{ margin: '0px !important' }}
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setIsAlert(false);
                                        setAlertMessage('');
                                        setSeverity('');
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            ) : ''}



            <Container maxWidth="md" component="main" >
                <Box sx={{ width: '100%' }} className={cx(classes.mainBlock)}>
                    <Typography variant='h2' component="h2" className={cx(classes.title)} >
                        Bienvenue sur votre interface de création de FAQ
                    </Typography>
                </Box>

                <Box sx={{ width: "100%" }}>
                    <Card sx={{ maxWidth: 345 }}>
                        <CardHeader
                            title="Liste de vos FAQ's"
                        /*subheader="Your created faq list here."*/
                        />
                        <CardContent>
                            <div style={{ overflowX: "auto" }}>
                                <table className="table is-striped is-fullwidth">
                                    <thead>
                                        <tr key={"faq-tr-header"}>
                                            <th>Titre de la FAQ</th>
                                            <th>Action</th>
                                            <th >Abonnement</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!openPageLoading ? (<>
                                            {faqs.length === 0 &&
                                                <tr key={"faq-tr-no"}>
                                                    <td colSpan='3' style={{ textAlign: "center", fontSize: "18px" }}>Aucune FAQ existante.</td>
                                                </tr>
                                            }
                                            {SUPER_ADMIN == parseInt(userId) ? (<>
                                                {faqs.length > 0 && faqs.map((faq, index) => (<>
                                                    <tr key={faq.id}>
                                                        <td style={{ width: "80%" }}>
                                                            {faq.name}
                                                        </td>
                                                        <td style={{ width: "20%" }}>
                                                            <div style={{ display: "flex" }}>
                                                                <Button type="button"
                                                                    key={"edit-faq-" + index}
                                                                    onClick={() => { window.location.href = `/faqs/edit/${faq.id}?id=${faq.id}` }}
                                                                    color='default' size="small"
                                                                    variant="contained"
                                                                    className="mr-1">Modifier</Button>
                                                                <Button type="button"
                                                                    key={"view-faq-" + index}
                                                                    href={`${SSR_PREFIX}?slug=${faq.slug}`}
                                                                    variant="contained"
                                                                    size="small" target="_blank"
                                                                    className="button is-success mr-1 ">Vue utilisateur</Button>
                                                            </div>
                                                        </td>
                                                        <td>Premium</td>
                                                    </tr>
                                                </>)
                                                )}
                                            </>) : (<>
                                                {faqs.length > 0 && faqs.map((faq, index) => (<>
                                                    <tr key={faq.id}
                                                        className={faq.is_free == 0 ?
                                                            (faq.sub_id == null || faq.s_status !== 1) ? classes.faqDisableRow : ""
                                                            : ""} >
                                                        <td key={faq.name}>{faq.name}</td>
                                                        <td key={"action-" + faq.id}>
                                                            <div style={{ display: "inline-block" }}>
                                                                <div style={{ marginBottom: "10px" }}>
                                                                    <Button type="button"
                                                                    key={"edit-faq-" + index}
                                                                    onClick={() => { window.location.href = `/faqs/edit/${faq.id}?id=${faq.id}` }}
                                                                    color='default' size="small"
                                                                    variant="contained"
                                                                    className="mr-1">Modifier / Partager</Button>
                                                                </div>
                                                                <div style={{ marginBottom: "10px" }}>
                                                                <Button type="button"
                                                                    key={"view-faq-" + index}
                                                                    href={`${SSR_PREFIX}?slug=${faq.slug}`}
                                                                    variant="contained"
                                                                    size="small" target="_blank"
                                                                    className="button is-success mr-1 ">Vue utilisateur</Button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td key={"plan-" + faq.id}>
                                                            {currentPlan == 'FREE' ? (<>
                                                                {faq.is_free == 1 ? (<>
                                                                    <p>Free</p>
                                                                </>) : (<>
                                                                    <p>Désactivé</p>
                                                                </>)}
                                                            </>) : (<>
                                                                {faq.is_free == 0 ? (<>
                                                                    {faq.s_status == 1 ? (<>
                                                                        <p>Premium</p>
                                                                    </>) : (<>
                                                                        <p>Désactivé</p>
                                                                    </>)}
                                                                </>) : (<>
                                                                    {faq.s_status == 1 ? (<>
                                                                        <p>Premium</p>
                                                                    </>) : (<>
                                                                        <p>Premium</p>
                                                                    </>)}
                                                                </>)}
                                                            </>)}
                                                        </td>
                                                    </tr>
                                                </>))}
                                            </>)}
                                        </>) : ""}
                                        <tr>
											<td colSpan='12' style={{ justifyContent: "center", textAlign: "center", fontSize: "18px" }}>
												<Button onClick={() => { window.location.href = "/faqs/create" }} variant="contained" color="primary" size="small" >Créer une nouvelle FAQ</Button>
											</td>
										</tr>
                                    </tbody>
                                </table>
                            </div>
                        </CardContent>
                    </Card>
                </Box>


            </Container>


        </div>

    )
}




export default Dashboard