import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { API_URL } from '../constants.js';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
	textAlign: 'center',
  padding:'120px 40px',
    backgroundColor: 'white',

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
  error: {
    color: 'red',
  },
    customBtn: {
    margin: '8px !important',
    '@media (max-width: 480px)': {
      width: '80%',
      textAlign: 'center',
      margin: '10px 0px!important',
    },
  },
  resetPasswordBtn: {
    margin: '8px !important',
    '@media (max-width: 480px)': {
      width: '80%',
      textAlign: 'center',
      margin: '10px 0px!important',
    },
  }
}));

const ForgetPassword = () => {
  const classes = useStyles();
  // create state variables for each input
  const [email, setEmail] = useState('');
  const history = useHistory();
  const [msg, setMsg] = useState('');

  const [emailMsg, setEmailMsg] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailMsg("");
    if (!email) {
      setEmailMsg('Veuillez renseigner votre email.');
    } else {
      var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
      if (!validRegex.test(email)) {
        setEmailMsg("Veuillez saisir une adresse électronique valide.");
        return false;
      } 
      try {
          const response = await axios.post( API_URL + '/auth/forget-password', {
              email: email
          });
          history.push({
            pathname: '/login',
            state: response.data.msg,
          });
      } catch (error) {
          if (error.response) {
            if (error.response.data.status === 403) {
              if (error.response.data.type[2] === 'email') {
                    setEmailMsg(error.response.data.emailMsg);
                } else {
                  if (error.response.data.type[0] === 'email') {
                    setEmailMsg(error.response.data.emailMsg);
                  } else {
                    setEmailMsg(error.response.data.emailMsg);
                  }
                }
            } else {
              setMsg(error.response.data.msg);
            }
          }
      }
    }
}

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
	<h1 className="login-title">Mot de passe oublié</h1>
    {msg !== '' ? <p className={classes.error}>{msg}</p> : ''}
      <TextField
        label="Email *"
        value={email}
        onChange={(e) => { 
          setEmail(e.target.value.trim());   
          setEmailMsg(''); 
          setMsg(''); 
        }}
        variant="outlined"
      />
      {emailMsg !== '' ? <p className={classes.error}>{emailMsg}</p> : ''}
      <div>
        <Button type="submit" variant="contained" color="primary" style={{ width: '60%' }} className={classes.customBtn}>
          Envoyer le nouveau mot de passe
        </Button>
        <Link to="/login">
            <Button type="button" variant="contained" color="secondary" className={classes.resetPasswordBtn}>
            Retour à la page de connexion
            </Button>
        </Link>
      </div>
    </form>
  );
};

export default ForgetPassword;