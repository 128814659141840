import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL, FAQ_PURCHASE_MONTHLY, FAQ_PURCHASE_YEARLY } from "../constants.js";
import { useHistory, useLocation } from "react-router-dom";
import {
  makeStyles, Container, Box, Grid,
  Card, CardActions, CardContent, CardHeader,
  Typography, Button, Paper
} from "@material-ui/core";

import { styled } from "@mui/material/styles";
import Profile from "./Profile.js";

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(10),
    backgroundColor: "white",
    "@media (max-width: 662px)": {
      padding: "100px 20px 100px 20px",
    },
    "& .MuiTextField-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: "100%",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  error: {
    color: "red",
  },
  success: {
    color: "green",
  },
  calculation: {
    display: "block",
  },
  calculationBtn: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#1b2854",
    marginBottom: "15px",
    "@media (max-width: 662px)": {
      fontSize: "18px !important",
    },
  },
  customBtn: {
    "@media (max-width: 662px)": {
      height: "42px",
      // margin: "8px !important",
      width: "100% !important",
      textAlign: "center",
      display: "flex",

      margin: 'unset !important',
    },
  },
  BacktoPasswordBtn: {
    "@media (max-width: 662px)": {
      height: "42px",
      width: "100% !important",
      textAlign: "center",
      display: "block",
    },
  },
  activeFaqCount: {
    fontWeight: '500',
    color: "#1b2854",
    textAlign: 'center',
    fontSize: '24px'
  },
  activeFaqText: {
    fontWeight: 'bold',
    color: "#3f51b5",
    textAlign: 'center',
    fontSize: '14px'
  },
  mainBlock: {
    padding: '30px 0px 40px'
  },
  /* saveChangePassword: {
     "@media (max-width: 662px)": {
       paddingBottom: "80px",
     },
   },
   inputSurname: {
     "@media (max-width: 662px)": {
       width: "100% !important",
       margin: 'unset !important',
       padding: '14px !important',
     },
   },
   inputFieldName: {
     "@media (max-width: 662px)": {
       width: "100% !important",
       margin: 'unset !important',
       padding: '8px 0px !important',
     },
   }, */
  tabsectionA: {
    "@media (max-width: 662px)": {
      margin: "5px",
      fontSize: "14px",
    },
  },
}));

const validatePassword = (password) => {
  const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
  return passwordRegex.test(password);
};

const UpdateProfile = ({ handleLoadingToggle }) => {

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  const [userId, setUserId] = useState(localStorage.getItem('user_id'));
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const [surname, setSurName] = useState("");
  const [updateSurname, setUpdateSurName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [currentPlan, setCurrentPlan] = useState('FREE');
  const [total_purchase, setTotalPurchase] = useState(0);
  const [total_created, setTotalCreated] = useState(0);
  const [available, setAvailable] = useState(0);
  const [totalAllowedFaq, setAllowedFaq] = useState(0);
  const [expiredFaq, setExpiredFaq] = useState(0);
  const [total_active, setTotalActive] = useState(0);

  const [buy_faq_monthly, setBuyFaqMonthly] = useState('');
  const [buy_faq_yearly, setBuyFaqYearly] = useState('');

  /** Validation */
  const [surnameMsg, setSurnameMsg] = useState("");
  const [lastNameMsg, setLastNameMsg] = useState("");
  const [emailMsg, setEmailMsg] = useState("");
  const [confPassMsg, setConfPassMsg] = useState("");
  const [passMsg, setPassMsg] = useState("");

  // Alerts
  const [isAlert, setIsAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info');

  const userData = async () => {
    if (userId === id) {
      const response = await axios.get(API_URL + "/users/get-profile/" + userId, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setSurName(response.data.surname);
      // setUpdateSurName(response.data.surname);
      setLastName(response.data.lastname);
      setEmail(response.data.email);
      setCompany(response.data.company);

      var link_params = new URLSearchParams({
        client_reference_id: response.data.customer_id,
        prefilled_email: response.data.email,
        utm_content: response.data.customer_id,
        utm_medium: response.data.email,
        utm_campaign: response.data.id,
      }).toString();
      setBuyFaqMonthly(FAQ_PURCHASE_MONTHLY + '?' + link_params);
      setBuyFaqYearly(FAQ_PURCHASE_YEARLY + '?' + link_params);

    } else {
      history.push("/dashboard");
    }
  };
  useEffect(async () => {
    userData();

    await axios.get(API_URL + "/users/get-user-dashboard/" + userId, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }).then((response) => {
      setTotalPurchase(response.data.result.total_purchased);
      setTotalCreated(response.data.result.total_created);
      setCurrentPlan(response.data.result.current_plan);
      setAvailable(response.data.result.available_faq);
      setAllowedFaq(response.data.result.total_allow_faq);
      setTotalActive(response.data.result.pro_faq_created + response.data.result.user_free_faq);
    }).catch((error) => {
      console.log(error)
    });

  }, []);

  const UpdateProfile = async (e) => {
    handleLoadingToggle(true)
    setMsg("");
    e.preventDefault();
    if (!surname) {
      setSurnameMsg("Veuillez renseigner votre prénom.");
      if (!lastname) {
        setLastNameMsg("Veuillez renseigner votre nom.");
      }
      if (!email) {
        setEmailMsg("Veuillez renseigner votre email.");
      }
    } else if (!surname) {
      setSurnameMsg("Veuillez renseigner votre prénom.");
    } else if (!lastname) {
      setLastNameMsg("Veuillez renseigner votre nom.");
    } else if (!email) {
      setEmailMsg("Veuillez renseigner votre email.");
    } else {
      try {
        await axios.post(
          API_URL + "/users/update-profile", {
          id: userId,
          surname: surname,
          lastname: lastname,
          company: company,
          email: email
        },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        setIsAlert(true);
        setAlertMessage("Votre profil a été mis à jour.");
        setSeverity('success');
      } catch (error) {
        if (error.response) {
          if (error.response.data.status === 403) {
            if (
              error.response.data.type[0] === "surname" &&
              error.response.data.type[1] === "lastName" &&
              error.response.data.type[2] === "email"
            ) {
              setSurnameMsg(error.response.data.surnameMsg);
              setLastNameMsg(error.response.data.lastNameMsg);
              setEmailMsg(error.response.data.emailMsg);
            } else {
              if (error.response.data.type[0] === "surname") {
                setSurnameMsg(error.response.data.surnameMsg);
              } else if (error.response.data.type[0] === "lastName") {
                setLastNameMsg(error.response.data.lastNameMsg);
              } else if (error.response.data.type[0] === "email") {
                setEmailMsg(error.response.data.emailMsg);
              } else if (error.response.data.type[0] === "emailValid") {
                setEmailMsg(error.response.data.emailMsg);
              } else if (error.response.data.type[0] === "emailExit") {
                setEmailMsg(error.response.data.emailMsg);
              }
            }
          } else {
            setIsAlert(true);
            setAlertMessage(error.response.data.msg);
            setSeverity('error');
          }
        }
      }
    }
    handleLoadingToggle(false)
  };


  const UpdatePassword = async () => {
    handleLoadingToggle(true);
    setPassMsg('');
	setConfPassMsg('');
    try {
      var validForm = true;
      if (!password) {
        setPassMsg('Veuillez entrer votre nouveau mot de passe svp');
        validForm = false;
      }
      if (!confPassword) {
        setConfPassMsg('Veuillez confirmer le mot de passe svp');
        validForm = false;
      } else {
        if (confPassword !== password) {
          setConfPassMsg('La confirmation de mot de passe ne correspond pas');
          validForm = false;
        }
      }
	  if (validForm) {
      if (!validatePassword(password)) {
        setPassMsg(
          'Le mot de passe doit contenir au moins 8 caractères dont 1 caractère spécial et 1 chiffre.'
        );
        validForm = false;
      }
    }
      if (validForm) {
        const token = localStorage.getItem("token");
        var response = await axios.post(
          API_URL + "/users/change-password",
          {
            email: email,
            password: password,
            confPassword: confPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setIsAlert(true);
        setAlertMessage('Mot de passe modifié avec succès.');
        setSeverity('success');
        setPassword(''); setConfPassword('');
        console.log(response);

      } else {
        handleLoadingToggle(false);
        return false;
      }
    } catch (error) {
      console.log('Errors', error.response.data);
      if (error.response) {
        if (error.response.data.status === 403) {
          if (error.response.data.type[0] === "password") {
            setPassMsg(error.response.data.emailMsg);
          }
          else if (error.response.data.type[0] === "confPassMsg") {
            setConfPassMsg(error.response.data.confPassMsg);
          }
        } else {
          setIsAlert(true);
          setAlertMessage(error.response.data.msg);
          setSeverity('error');
        }
      }
    }
    setPassword(''); setConfPassword('');
    handleLoadingToggle(false)
  }

  const handleSurname = (value) => {
    setSurName(value);
  }
  const handleLastname = (value) => {
    setLastName(value);
  }
  const handleCompany = (value) => {
    setCompany(value);
  }

  return (
    <div className={classes.root}>
      {isAlert ? (
        <Box sx={{ width: '100%' }} >
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ width: '100%' }}
            spacing={2}
            open={isAlert}
            autoHideDuration={5000}
            onClose={() => {
              setIsAlert(false);
              setAlertMessage('');
              setSeverity('');
            }}>
            <Alert
              sx={{ alignItems: 'center' }}
              severity={severity}
              action={
                <IconButton
                  sx={{ margin: '0px !important' }}
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsAlert(false);
                    setAlertMessage('');
                    setSeverity('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {alertMessage}
            </Alert>
          </Snackbar>
        </Box>
      ) : ''}

      <Container maxWidth="md" component="main" >

        <Box sx={{ width: "100%" }}>
          <Profile
            msg={msg}
            surname={surname}
            surnameMsg={surnameMsg}
            lastname={lastname}
            lastNameMsg={lastNameMsg}
            company={company}
            email={email}
            password={password}
            confPassword={confPassword}
            setSurName={handleSurname}
            setLastName={handleLastname}
            setCompany={handleCompany}
            setPassword={setPassword}
            setConfPassword={setConfPassword}
            passMsg={passMsg}
            confPassMsg={confPassMsg}
            setPassMsg={setPassMsg}
            setConfPassMsg={setConfPassMsg}
            UpdatePassword={UpdatePassword}
            UpdateProfile={UpdateProfile}
          />
        </Box>

        <hr style={{ border: "0.5px solid" }} />

        <Box sx={{ width: "100%" }}>
		<h2 class="makeStyles-calculationBtn-20">Mon abonnement</h2>
          <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3} key="box-1">
              <Card variant="outlined">
                <CardContent>
                  <Typography className={classes.activeFaqText} variant="subtitle1" component="div">
                    Abonnement actuel
                  </Typography>

                  <Typography className={classes.activeFaqCount} variant="h4" component="h3" >
                                                            {currentPlan == 'FREE' ? (<>
                                                                    <p>Free</p>
                                                                </>) : (<>
                                                                    <p>Premium</p>
                                                                </>)}
                  </Typography>

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} key="box-2">
              <Card variant="outlined">
                <CardContent>
                  <Typography className={classes.activeFaqText} variant="subtitle1" component="div">
                    Nombre de FAQ authorisées pour votre abonnement actuel
                  </Typography>

                  <Typography className={classes.activeFaqCount} variant="h4" component="h3" >
                    {totalAllowedFaq}
                  </Typography>

                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={3} key="box-3">
              <Card variant="outlined">
                <CardContent>
                  <Typography className={classes.activeFaqText} variant="subtitle1" component="div">
                    Nombre de FAQ créées
                  </Typography>

                  <Typography className={classes.activeFaqCount} variant="h4" component="h3" >
                    {total_created} {/* {total_active} */}
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
			
			
			{/*
           <Grid item xs={12} sm={6} md={3} key="box-4">
              <Card variant="outlined">
                <CardContent>
                  <Typography className={classes.activeFaqText} variant="subtitle1" component="div">
                    Nouvelles FAQ disponibles
                  </Typography>

                  <Typography className={classes.activeFaqCount} variant="h4" component="h3" >
                    {available}
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
			*/}
          </Grid>
        </Box>
		

        {currentPlan == 'PRO' ? (<>
          {total_created > totalAllowedFaq ? (<>
            <p style={{ margin: '15px 0px 0px' }}>Vous avez plus de FAQ que ce qu'authorise votre nombre d'abonnement actuel, cela désactive automatiquement la visibilité de certaines FAQ de votre compte. Afin de souscrire à un abonnement, veuillez vous rendre sur la page d'une FAQ désactivée en <a href="https://app.faqguide.help/dashboard"> sélectionnant une FAQ grisée depuis votre listing de FAQ</a> puis suivre le lien pour souscrire à un nouvel abonnement.</p>
                         </>) : (<>
                         </>)}
		<p style={{ margin: '15px 0px 0px' }}>Pour tout problème rencontré ou si vous souhaitez résilier votre abonnement, veuillez nous contacter avec le bouton ci-dessous.</p>
		  <Button fullWidth
			style={{ margin: '15px 0px 15px' }}
            type="button"
            variant="contained"
            color="primary"
			marginLeft="0"
            onClick={() => window.location.href = "https://faqguide.help/contact/"} >
            Contactez-nous
          </Button>
        </>) : ""}

        <hr style={{ border: "0.5px solid" }} />

        {/*
        <Box sx={{ width: "100%" }}>
          <Grid container spacing={5} >
            <Grid item key="Free-plan" xs={12} sm={6} md={4} >
              <Card variant="outlined">
                <CardHeader
                  title="FREE"
                  subheader=""
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />

                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      ${0}
                    </Typography>
                  </Box>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="1 FAQ" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="25 Questions / FAQ" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="5 Rubriques / FAQ" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CloseIcon color='error' />
                      </ListItemIcon>
                      <ListItemText primary="Protection de la FAQ par mot de passe" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CloseIcon color='error' />
                      </ListItemIcon>
                      <ListItemText primary="Empêcher l'indexation par les moteurs de recherche" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CloseIcon color='error' />
                      </ListItemIcon>
                      <ListItemText primary="Cacher le logo FAQGuide en bas de la FAQ" />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
            <Grid item key="monthly-plan" xs={12} sm={6} md={4} >
              <Card variant="outlined">
                <CardHeader
                  title="Premium mensuel"
                  subheader=""
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {3}€ TTC
                    </Typography>
                  </Box>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Achetez autant de FAQ que vous le souhaitez !" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Questions illimitées" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Rubriques illimitées" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Protection de la FAQ par un mot de passe" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Empêcher l'indexation par les moteurs de recherche" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Cacher le logo FAQGuide en bas de la FAQ" />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button fullWidth
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.href = buy_faq_monthly} >
                    Acheter pour 1 mois (renouvelable)
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item key="yearly-plan" xs={12} sm={6} md={4} >
              <Card variant="outlined">
                <CardHeader
                  title="Premium annuel"
                  subheader=""
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                      {30}€ TTC
                    </Typography>
                  </Box>
                  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Achetez autant de FAQ que vous le souhaitez !" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Questions illimitées" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Rubriques illimitées" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Protection de la FAQ par un mot de passe" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Empêcher l'indexation par les moteurs de recherche" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckIcon color='success' />
                      </ListItemIcon>
                      <ListItemText primary="Cacher le logo FAQGuide en bas de la FAQ" />
                    </ListItem>
                  </List>
                </CardContent>
                <CardActions>
                  <Button fullWidth
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.href = buy_faq_yearly}>
                    Acheter pour 1 an (renouvelable)
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>

                    */}
      </Container>
    </div>
  );
};



export default UpdateProfile;
