import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { createTheme } from "@material-ui/core/styles";
import { useHistory, Link, NavLink } from "react-router-dom";
import axios from "axios";
import { API_URL, SUPER_ADMIN } from "../../constants.js";
import Box from "@mui/material/Box";
import FAQGUIDE_LOGO from "../../images/Logo-FAQGUIDE.svg";
import { Button } from "@material-ui/core";
import { makeStyles } from "tss-react/mui.js";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const theme = createTheme({});
const useStyles = makeStyles()(() => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    display: "flex",
    float: "right",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    width: "100%",
    maxWidth: "230px",
    marginTop: "10px"
  },
  stickyHeader: {
    flexGrow: "1",
    width: "100%",
    position: "fixed",
    top: "0px",
    zIndex: "9",
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  textConnect: {
    fontSize: "15px",
  },
  iconArrow: {
    fontSize: "1rem !important",
    position: "relative",
    top: "3px",
  },
  customTistTitle: {
    color: '#485fc7',
  },
  accountLink: {
    borderColor: "transparent",
    cursor: "pointer",
    justifyContent: "center",
    paddingBottom: "calc(0.5em - 1px)",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "calc(0.5em - 1px)",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  btnSignUp: {
    background: "#1b2854",
    "&:hover": {
      background: "#000",
    },
    borderColor: "transparent",
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
    cursor: "pointer",
    justifyContent: "center",
    paddingBottom: "calc(0.5em - 1px)",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "calc(0.5em - 1px)",
    textAlign: "center",
    whiteSpace: "nowrap",
    borderRadius: "6px",
  },
  btnSignIn: {
    borderColor: "transparent",
    cursor: "pointer",
    justifyContent: "center",
    paddingBottom: "calc(0.5em - 1px)",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "calc(0.5em - 1px)",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  btnSignOut: {
    background: "red !important",
    borderColor: "transparent",
    color: "#fff !important",
    cursor: "pointer",
    justifyContent: "center",
    paddingBottom: "calc(0.5em - 1px)",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "calc(0.5em - 1px)",
    textAlign: "center",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "red",
      color: "#fff",
    },
  },
  menuItem: {
    paddingBottom: "calc(0.5em - 1px)",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: "calc(0.5em - 1px)",
  }
}));

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function PersistentDrawerRight({ handleLoadingToggle }) {

  const { classes, cx } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [drwawerOpen, setDrwawerOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [faqItems, setFaqItems] = useState([]);
  const [user, setUser] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem("token"));
  const [userID, setUserID] = useState(localStorage.getItem("user_id"));
  const history = useHistory();
  const [subscribedFaq, setSubscribedFaq] = useState(0);

  const handleClicks = () => {
    setOpen1(!open1);
  };
  const handleClicks1 = () => {
    setOpen2(!open2);
  };
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleDrawerOpen = () => {
    setDrwawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrwawerOpen(false);
  };
  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu-1").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight,
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }
    setOpen(false);
  };
  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
    setOpen1(true);
  };
  const handleClose1 = (e) => {
    const menu2 = document.getElementById("simple-menu-2").children[2];
    const menuBoundary2 = {
      left: menu2.offsetLeft,
      top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
      right: menu2.offsetLeft + menu2.offsetHeight,
      bottom: menu2.offsetTop + menu2.offsetHeight,
    };
    if (
      e.clientX >= menuBoundary2.left &&
      e.clientX <= menuBoundary2.right &&
      e.clientY <= menuBoundary2.bottom &&
      e.clientY >= menuBoundary2.top
    ) {
      return;
    }
    setOpen1(false);
  };

  const refreshToken = async (e) => {
      if (userToken !== undefined && userToken !== null && userToken !== "") {
        try {
        const response = await axios.get(API_URL + "/users/get-auth-user/" + userToken, {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }).then(function (response) {
            setUser(response.data);
            setIsLoggedIn(true);
            setIsLoggedOut(false);
          });
        } catch (error) {
          if (error.response) {
            history.push("/");
          }
        }
      } else {
        setUserToken(null);
        setUserID(null);
      }
  };

  // Login check Effect
  useEffect(() => {
    refreshToken();
  }, []);

  // FAQ data
  useEffect(async () => {
    if (
      userToken !== undefined &&
      userToken !== null &&
      userID !== undefined &&
      userID !== ""
    ) {
      await axios
        .get(API_URL + "/faqs/sidebar-list", {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
          params: {
            userId: userID,
          },
        })
        .then((response) => {
          setFaqItems(response.data);
        })
        .catch(() => {
          setFaqItems([]);
        });

      var result = await axios.get(API_URL + '/subscription/active/' + userID, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (typeof result.data.result != 'undefined') {
        if (result.data.result == 0) {
          setSubscribedFaq(1);
        } else {
          setSubscribedFaq(parseInt(result.data.result));
        }
      } else {
        setSubscribedFaq(1);
      }
    }

  }, []);

  const Logout = async () => {

    try {
      const response = await axios.delete(API_URL + "/auth/logout", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      setIsLoggedIn(false);
      setIsLoggedOut(true);
      setUserToken(null);
      setUserID(null);
      localStorage.clear("token");
      localStorage.clear("user_id");
      history.push("/login");
      handleLoadingToggle(false)
    } catch (error) {
    }
  };
  return (
    <Box sx={{ display: "flex" }} >
      <CssBaseline />
      <AppBar color="inherit" position="fixed" open={open}>
        <Toolbar>
          <Typography variant="subtitle1" noWrap sx={{ flexGrow: 1 }} >
            <img src={FAQGUIDE_LOGO} alt="logo" className={cx(classes.logo)} onClick={() => {
              handleDrawerClose();
              history.push('/dashboard')
            }} />
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={drwawerOpen}

      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <a
                  className={cx(classes.btnSignIn)}
                  href="https://faqguide.help/"
                  aria-current="page"
                >
                  Notre solution
                </a>
              </ListItemText>
            </ListItemButton>
          </ListItem>

		{/*
          <ListItem disablePadding>
            <ListItemButton>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                sx={{ width: "100%" }}
                className={cx(classes.menuItem)}
              > 
                <ListItemButton onClick={handleClicks}>
                  <ListItemText primary="Exemples de FAQ" className={cx(classes.customTistTitle)} />
                  {open1 ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open1}>
                  <List component="div" disablePadding >
                    <ListItemButton className="site-color-section">
                      <a href="https://faqguide.help/exemple-faq-locations-saisonnieres/" className={cx(classes.menuItem)}>
                        Exemple FAQ Locations saisonnière
                      </a>
                    </ListItemButton>
                    <ListItemButton className="site-color-section">
                      <a href="https://faqguide.help/exemple-faq-hotels-et-restaurant/" className={cx(classes.menuItem)}>
                        Exemple FAQ Hôtels et Restaurants
                      </a>
                    </ListItemButton>
                    <ListItemButton className="site-color-section">
                      <a href="https://faqguide.help/exemple-faq-cabinets-medicaux/" className={cx(classes.menuItem)}>
                        Exemple FAQ Cabinets médicaux
                      </a>
                    </ListItemButton>
                    <ListItemButton className="site-color-section">
                      <a href="https://faqguide.help/exemple-faq-magasins-et-commerces/" className={cx(classes.menuItem)}>
                        Exemple FAQ Magasin &amp; Commerce
                      </a>
                    </ListItemButton>
                  </List>
                </Collapse>
              </List>
            </ListItemButton>
          </ListItem>
		*/}
          {!isLoggedIn ? (
            <>
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText>
                    <Link
                      to="/login"
                      underline="none"
                      variant="outlined"
                      className={cx(classes.btnSignIn)}
                    >
                      Se connecter
                    </Link>
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText>
                    <Link
                      to="/register"
                      underline="none"
                      variant="contained"
                      className={cx(classes.btnSignUp)}
                    >
                      Inscription
                    </Link>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </>
          ) : (
            <>

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText>
                    <Link
                      to={`/dashboard`}
                      className={cx(classes.accountLink)}
                      onClick={handleDrawerClose}
                    >
                      Mes FAQ
                    </Link>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
            <ListItemButton>
              <ListItemText>
                <a
                  className={cx(classes.btnSignIn)}
                  href="https://faqguide.help/aide-creation-FAQ/"
                  aria-current="page"
                  target="_blank"
                >
                  Aide pour créer ma FAQ
                </a>
              </ListItemText>
            </ListItemButton>
           </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton>
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    sx={{ width: "100%" }}
                    className={cx(classes.menuItem)}
                  >
                    <ListItemButton onClick={handleClicks1}>
                      <ListItemText primary="Mes FAQ's" className={cx(classes.customTistTitle)} />
                      {open2 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open2}>
                      <List component="div" disablePadding>
                        {(SUPER_ADMIN === parseInt(userID)) ? (<>

                          {faqItems.map((faq, index) =>
                            <ListItemButton key={`mfi-${index}`} className="site-color-section">
                              <Link
                                exact
                                to={`/faqs/edit/${faq.id}?id=${faq.id}&slug=${faq.slug}`}
                                key={"item-" + faq.id}
                                onClick={handleDrawerClose}
                                className={cx(classes.menuItem)}
                              >
                                {faq.name}
                              </Link>
                            </ListItemButton>
                          )}


                        </>) : (<>

                          {faqItems.slice(0, 5).map((faq, index) =>
                            subscribedFaq > index ? (<>
                              <ListItemButton key={`mfi-${index}`} className="site-color-section">
                                <Link
                                  exact
                                  to={`/faqs/edit/${faq.id}?id=${faq.id}&slug=${faq.slug}`}
                                  key={"item-" + faq.id}
                                  onClick={handleDrawerClose}
                                  className={cx(classes.menuItem)}
                                >
                                  {faq.name}
                                </Link>
                              </ListItemButton>
                            </>) : ''
                          )}

                        </>)}
                        <ListItemButton key='mfc' className="site-color-section">
                          <NavLink to="/faqs/create" onClick={handleDrawerClose} className={cx(classes.menuItem)}>Create a new FAQ</NavLink>
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </List>
                </ListItemButton>
              </ListItem> */}

              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemText>
                    <Link
                      to={`/my-account?id=${user.id}`}
                      className={cx(classes.accountLink)}
                      onClick={handleDrawerClose}
                    >
                      Mon compte
                    </Link>
                  </ListItemText>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>

                <ListItemText>
                  <Button
                    onClick={() => {
                      handleLoadingToggle(true);
                      Logout();
                      handleDrawerClose();
                    }}
                    size="small"
                    className={cx(classes.btnSignOut) + " ml-2 "}
                    variant="contained"

                  >
                    Déconnexion
                  </Button>
                </ListItemText>

              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </Box>
  );
}
