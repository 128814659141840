import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMServer from 'react-dom/server';


import App from './App';
import "bulma/css/bulma.css";
import "./App.css";
import axios from "axios";
axios.defaults.withCredentials = true;


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


