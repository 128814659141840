import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { API_URL, FAQ_PURCHASE_MONTHLY, FAQ_PURCHASE_YEARLY, SUPER_ADMIN } from '../constants';
import { useHistory, useLocation } from "react-router-dom";
import {
    Container, Box,
    Card, CardContent, CardHeader,
    Typography, Button
} from "@material-ui/core";
import { makeStyles } from 'tss-react/mui';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const useStyles = makeStyles()(theme => ({
    root: {
        backgroundColor: 'white',
        marginTop: "65px",
        marginBottom: "100px",
        ['& .MuiCardHeader-title']: {
            fontSize: '26px',
            fontWeight: 'bold',
            color: '#1b2854'
        },
        ['& .MuiCardHeader-action']: {
            marginTop: '0px',
            marginRight: '0px'
        }
    },
    title: {
        fontSize: '48px !important',
        fontWeight: 'bold !important',
        color: '#1b2854 !important'
    },
    desc : {
        fontSize: '26px !important',
        fontWeight: '400 !important',
        color: '#000000 !important'
    },
    activeFaqCount: {
        fontWeight: '500',
        color: "#1b2854",
        textAlign: 'center',
        fontSize: '24px'
    },
    activeFaqText: {
        fontWeight: 'bold',
        color: "#3f51b5",
        textAlign: 'center',
        fontSize: '14px'
    },
    mainBlock: {
        padding: '30px 0px 40px'
    },
    buttonGray: {
        color: "#ffffff !important",
        background: '#767676 !important',
        ackground: '#767676 !important',
        marginRight: '5px !important'
    },
    faqDisableRow: {
        background: "rgba(54, 54, 54, 0.22) !important",

    },
    upgrateText: {
        color: '#3f51b5 !important',
        fontStyle: 'italic !important',
        fontWeight: '600 !important',
    }
}));

const Thankyou = ({ handleLoadingToggle, forceUpdate }) => {

    const history = useHistory();
    const location = useLocation();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const [checkout, setCheckout] = useState([]);
    const [title, setTitle] = useState("Success!");
    const [message, setMessage] = useState("We received your purchase request; we'll be in touch shortly!");
    const [icon, setIcon] = useState("Your payment process successfully! Your FAQ subscription is ");

    const [isThankyou, setIsThankyou] = useState(false);
    // Checkout Success

    useEffect(async () => {
        
        handleLoadingToggle(true);

        if (urlParams.get('checkout_session_id')) 
        {
            var checkout_session_id = urlParams.get('checkout_session_id'); 
            var utm_campaign = urlParams.get('utm_campaign'); // user id
            var utm_content = urlParams.get('utm_content'); // customer_id
            var utm_source = urlParams.get('utm_source'); // FAQ ID
            var utm_medium = urlParams.get('utm_medium'); // payment type (purchase or upgrade)
            var utm_term = urlParams.get('utm_term'); // payment type (purchase or upgrade)

            if(typeof localStorage.getItem("type") == 'string' ) {

                var type = localStorage.getItem("type");
                var period = localStorage.getItem("period");
                var faqId = localStorage.getItem("faq_item");

                if(localStorage.getItem("type") == 'upgrade') {
                    // Upgrade plan purchaseOrUpgrade
                    console.log("Upgrade plan");
                    await axios.post(API_URL + '/subscription/purchaseOrUpgrade', { 
                            checkoutSessionId: checkout_session_id, 
                            user_id: utm_campaign,
                            type :type, 
                            period :period,
                            faq_id: faqId,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(result => { 
                            console.log('CHECKOUT: ', result.data);
                            if(typeof result.data.faq_id != 'undefined') {
                                handleLoadingToggle(false);
                                history.push("/faqs/edit/" + faqId + "?id=" + faqId);
                                localStorage.removeItem("type");
                                localStorage.removeItem("period");
                                localStorage.removeItem("faq_item");
                            }
                        })
                        .catch(error => {
                            console.error('ERROR: ', error);
                            handleLoadingToggle(false);
                            history.push("/dashboard");
                            localStorage.removeItem("type");
                            localStorage.removeItem("period");
                            localStorage.removeItem("faq_item");
                        });

                } else {
                    // Purchase plan
                    console.log("Purchase plan");

                    await axios.post(API_URL + '/subscription/purchaseOrUpgrade', { 
                            checkoutSessionId: checkout_session_id, 
                            user_id: utm_campaign,
                            type :type, 
                            period :period,
                            faq_id: faqId,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem('token')}`
                            }
                        })
                        .then(result => { 
                            console.log('CHECKOUT: ', result.data);
                            if(typeof result.data.faq_id != 'undefined') {
                                handleLoadingToggle(false);
                                history.push("/faqs/edit/" + faqId + "?id=" + faqId);
                                localStorage.removeItem("type");
                                localStorage.removeItem("period");
                                localStorage.removeItem("faq_item");
                            }
                        })
                        .catch(error => {
                            console.error('ERROR: ', error);
                            handleLoadingToggle(false);
                            history.push("/dashboard");
                            localStorage.removeItem("type");
                            localStorage.removeItem("period");
                            localStorage.removeItem("faq_item");
                        });
                }

            } else {
                console.log("Purchase plan by admin");
                await axios.post(API_URL + '/subscription/create', {
                    checkout_session_id: checkout_session_id, 
                    checkoutSessionId: checkout_session_id, 
                    user_id: utm_campaign,
                    email: utm_content
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then(result => { 
                    console.log('CHECKOUT: ', result.data);
                    if(typeof result.data.faq_id != 'undefined') {
                        handleLoadingToggle(false);
                        //history.push("/faqs/edit/" + result.data.faq_id + "?id=" + result.data.faq_id);
                        localStorage.removeItem("type");
                        localStorage.removeItem("period");
                        localStorage.removeItem("faq_item");
                        setIsThankyou(true);
                    }
                })
                .catch(error => {
                    console.error('ERROR: ', error);
                    handleLoadingToggle(false);
                    history.push("/dashboard");
                    localStorage.removeItem("type");
                    localStorage.removeItem("period");
                    localStorage.removeItem("faq_item");
                });
            }

        } else {
            handleLoadingToggle(false);
            history.push("/dashboard");
            localStorage.removeItem("type");
            localStorage.removeItem("period");
            localStorage.removeItem("faq_item");
        }

    }, []); 


    const { classes, cx } = useStyles();
    return (
        <div className={cx(classes.root)}>
            {isThankyou ? (<>
                <Container maxWidth="md" component="main" >
                    <Box sx={{ width: '100%' }} className={cx(classes.mainBlock)}>
                        <CheckIcon color="success" fontSize="large" />

                        <Typography variant='h1' component="h1" className={cx(classes.title)} >
                             { title }
                        </Typography>
                        <Typography  variant="subtitle1" className={cx(classes.desc)}>
                             We received your purchase request;
                             we'll be in touch shortly!
                        </Typography>
                    </Box>
                </Container>
            </>) : ""}
        </div> 
    )
}


export default Thankyou