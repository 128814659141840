import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { makeStyles, Box, Grid, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';	
import { API_URL } from '../constants.js';


import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding:'120px 40px',
    backgroundColor: "white",
	
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "300px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  error: {
    color: "red",
    textAlign: 'center'
  },
  success: {
    color: "green",

  },
  customBtn: {
    margin: '8px !important',
    '@media (max-width: 480px)': {
      width: '80%',
      textAlign: 'center',
      margin: '10px 0px!important',
    },
  },
  resetPasswordBtn: {
    margin: '8px !important',
    '@media (max-width: 480px)': {
      width: '80%',
      textAlign: 'center',
      margin: '10px 0px!important',
    },
  }
}));

const Login = ({ handleLoadingToggle }) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [passMsg, setPassMsg] = useState('');
    const history = useHistory();
    const classes = useStyles();
    const [userToken, setUserToken] = useState(localStorage.getItem('token'));
    const [userID, setUserID] = useState(localStorage.getItem('user_id'));
    const location = useLocation();
    const [redirectMsg, setRedirectMsg] = useState('');

     // Alerts
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    useEffect(() => {
      if (location.state != "") {
        setRedirectMsg(location.state)
      }
    }, [location])

    window.history.replaceState({}, '');

      useEffect(async () => {
        try {
          if (userToken !== undefined && userToken !== null && userToken !== '') {
            await axios.get(API_URL + '/users/get-auth-user/' + userToken, {
              headers: {
                Authorization: `Bearer ${userToken}`
              }
            }).then(function (response) {
              // setUserID(response.data.id);
              history.push("/dashboard");
            }).catch(error => {
              localStorage.removeItem("token");
              localStorage.removeItem("user_id");
              setUserToken(null);
              setUserID(null);
            });
          } else {
            setUserToken(null);
            setUserID(null);
          }
        } catch (error) {
          // setUserToken(null);
          // setUserID(null);
          // localStorage.removeItem("token");
          // localStorage.removeItem("user_id");
          console.log(error);
        }

        if (location.state != undefined && location.state !== '') {
          setAlertMessage(location.state);
          setSeverity('success');
          setIsAlert(true);
          window.history.replaceState({}, "");
        }
      }, []);

      const Auth = async (e) => {
          e.preventDefault();
          handleLoadingToggle(true);
          if (!(email, password)) {
            if (!email) {
              setEmailMsg("Veuillez renseigner votre email.");
            } else {
              var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (!validRegex.test(email)) {
                setEmailMsg("Veuillez saisir une adresse électronique valide.");
              } else {
                setEmailMsg("");
              }
            }
            if (!password) {
              setPassMsg("Veuillez renseigner votre mot de passe.");
            } else {
              setPassMsg("");
            }
            handleLoadingToggle(false);
          } else {
            try {
              await axios.post(API_URL + "/auth/login", {
                email: email,
                password: password,
              }).then(response => {
                if (typeof response.data.user != 'undefined') {
                  localStorage.setItem("token", response.data.user.refresh_token);
                  localStorage.setItem("user_id", response.data.user.id);
                  handleLoadingToggle(false);
                  history.push("/dashboard");
                } else {
                  setIsAlert(true);
                  setAlertMessage(response.data.msg);
                  setSeverity('info');
                  handleLoadingToggle(false);
                }
              }).catch(error => {
                if (error.response) {
                  if (error.response.data.status === 403) {
                    if (
                      error.response.data.type[0] === "email" &&
                      error.response.data.type[1] === "password"
                    ) {
                      setEmailMsg(error.response.data.emailMsg);
                      setPassMsg(error.response.data.passMsg);
                    } else {
                      if (error.response.data.type[0] === "email") {
                        setEmailMsg(error.response.data.emailMsg);
                      } else {
                        setPassMsg(error.response.data.passMsg);
                      }
                    }
                  } else {
                    setAlertMessage(error.response.data.msg);
                    setSeverity('error');
                    setIsAlert(true);
                  }
                }
                setUserToken(null);
                setUserID(null);
                handleLoadingToggle(false);
              });

            } catch (error) {              
              handleLoadingToggle(false);
            }
          }
      };

    return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} md={12} sm={12}>

          {isAlert ? (
            <Box sx={{ width: '100%' }} >
              <Snackbar sx={{ width: '100%' }} spacing={2}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isAlert}
                autoHideDuration={5000}
                onClose={() => {
                  setIsAlert(false);
                  setAlertMessage('');
                  setSeverity('');
                }}>
                <Alert
                  sx={{ alignItems: 'center' }}
                  severity={severity}
                  action={
                    <IconButton
                      sx={{ margin: '0px !important' }}
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setIsAlert(false);
                        setAlertMessage('');
                        setSeverity('');
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>
                  {alertMessage}
                </Alert>
              </Snackbar>
            </Box>
          ) : ''}

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
            className={classes.root}
            onSubmit={Auth}
          >
            {msg !== "" ? <p className={classes.error}>{msg}</p> : ""}

            <h1 className="login-title">Connexion</h1>
            <div>
              <TextField
                label="Email *"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  setEmailMsg("");
                  setMsg("");
                }}
                variant="outlined"
              />
              {emailMsg !== "" ? (
                <p className={classes.error}>{emailMsg}</p>
              ) : (
                ""
              )}
            </div>

            <div>
              <TextField
                label="Mot de passe *"
                type="password"
                value={password}

                onChange={(e) => {
                  setPassword(e.target.value);
                  setPassMsg("");
                  setMsg("");
                }}
                autoComplete="new-password"
                variant="outlined"
              />

              {passMsg !== "" ? <p className={classes.error}>{passMsg}</p> : ""}
            </div>

            <div style={{ textAlign: 'center' }} >
              <Button
              size="small"
                type="submit"
                variant="contained"
                color="primary"
				style={{ width: '60%' }} 
                className={classes.customBtn}
              >
                Connexion
              </Button>
  <Typography variant="subtitle2" component="p" style={{ textAlign: 'center' }}>
    <Link to="/forget-password" style={{ color: 'gray', fontWeight: '200' }}>
      Mot de passe oublié
    </Link>
  </Typography>

              <Typography variant="subtitle1" component="p" style={{ textAlign: 'center' }} >
                Si vous n'avez pas encore de compte, <a href="/register">inscrivez-vous ici</a>
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
    )
}

export default Login
