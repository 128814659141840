const API_URL = 'https://api.faqguide.help/api';
const API_SITE = 'https://api.faqguide.help/';
const SUPER_ADMIN = 40;
const SITE_PREFIX = 'https://app.faqguide.help';
const SSR_PREFIX = 'https://faqguide.help/faq/';

const FAQ_PURCHASE_MONTHLY = 'https://buy.stripe.com/aEUdSMdDzeN02rudQQ'; // Premium mensuel
const FAQ_PURCHASE_YEARLY = 'https://buy.stripe.com/5kAdSM0QN5cq1nq145'; // Premium annuel

// mode test stripe premium annuel : https://buy.stripe.com/test_8wM00Nd4P85l8TK8ww
// mode test stripe premium mensuel : https://buy.stripe.com/test_7sIdRDc0L0CTb1SdQR

const FREE_FAQ_CATEGORY_LIMIT = 5;
const FREE_FAQ_CATEGORY_QUESTION_LIMIT = 25;
 

const STRIPE_PUBLISHABLE = "pk_live_51MYWjQHzSu3Akc6zMWVqATT0hu45K3jRhiOFdZD3UjIrnAFKMTV2hptGc4o7nCaWU8E7DKHYMSB79f7RDHaEcnEh00OSsqoYgn";
const PAYMENT_SERVER_URL = "https://api.faqguide.help/api/payments/checkout";

export { 
	API_URL, 
	API_SITE, 
	SITE_PREFIX,
    SSR_PREFIX,
	STRIPE_PUBLISHABLE,
    PAYMENT_SERVER_URL,
    FAQ_PURCHASE_YEARLY,
    FAQ_PURCHASE_MONTHLY,
    SUPER_ADMIN,
    FREE_FAQ_CATEGORY_LIMIT,
    FREE_FAQ_CATEGORY_QUESTION_LIMIT
} /* If multiple pass comma separted Example export { Url, Variable1, Variable2 }*/
