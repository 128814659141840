import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  Box,
  Container, 
  Grid
} from "@material-ui/core";
import { Link } from "react-router-dom";


const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    display: "flex",
  },
 logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  stickyFooter:{
    flexGrow: '1',
    position: 'fixed',
    bottom: '0',
    zIndex: '1200 !important',
    width: "100%"
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  textConnect: {
    fontSize: "15px"
  }
}));

function Footer(props) {

  const classes = useStyles();

  const color = (typeof props.theme != '') ? '#1b2854' : '#1b2854';
  const btnSignIncolor = (typeof props.theme != '') ? '#FFFFFF' : '#1b2854';
  const btnSignUpcolor = (typeof props.theme != '') ? '#FFFFFF' : '#1b2854';
  

  return (<>
    <Box className={classes.stickyFooter} >
        <AppBar position="static" style={{ background:color }}>
          <CssBaseline />
          <Container >
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <a target="_blank" className="font-white" href="https://faqguide.help/mentions-legales/">Mentions légales</a> &nbsp;|&nbsp; <a href="https://faqguide.help/conditions-generales-d-utilisation/" target="_blank" className="font-white">Conditions Générales d’Utilisation</a> &nbsp;|&nbsp; © FAQGUIDE 2023
            </Grid>
          </Toolbar>
          </Container>
        </AppBar>
    </Box>
    </>);
}
export default Footer;