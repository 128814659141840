import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useHistory } from "react-router-dom";
import { makeStyles, Box } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Cached';
import { API_URL } from '../constants.js';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '120px 40px',
    backgroundColor: 'white',

    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '300px',
    },
    '& .MuiButtonBase-root': {
      margin: theme.spacing(2),
    },
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  success: {
    color: 'green'
  },
  calculationBtn: {
    "@media (max-width: 662px)": {
      whiteSpace: "nowrap",
    },
  }
}));

const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

const Register = ({ handleLoadingToggle }) => {

    const [surname, setSurName] = useState('');
    const [lastname, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useHistory();
    const classes = useStyles();
    const [answer, setAnswer] = useState('');
	const [buttonText, setButtonText] = useState(randomNumberInRange(1, 20) + ' + ' + randomNumberInRange(1, 20));
    const [num1, setNum] = useState(randomNumberInRange(1, 20));
    const [num2, setNum2] = useState(randomNumberInRange(1, 20));
    const [formSubmit, setFormSubmit] = useState(false);
    const [userToken, setUserToken] = useState(localStorage.getItem('token'));
    const [userID, setUserID] = useState(localStorage.getItem('user_id'));
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [acceptPrivacy, setAcceptPrivacy] = useState(false);

	useEffect(() => {
    const initialNum1 = randomNumberInRange(1, 20);
    const initialNum2 = randomNumberInRange(1, 20);
    setNum(initialNum1);
    setNum2(initialNum2);
    setButtonText(initialNum1 + ' + ' + initialNum2);
  }, []);

    /** Validation */
    const [surnameMsg, setSurnameMsg] = useState('');
    const [lastNameMsg, setLastNameMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [passMsg, setPassMsg] = useState('');
    const [confPassMsg, setConfPassMsg] = useState('');
    const [ansMsg, setAnsMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
	const [privacyMsg, setPrivacyMsg] = useState('');
	const [termsMsg, setTermsMsg] = useState('');

      // Alerts
    const [isAlert, setIsAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [severity, setSeverity] = useState('info');

    const nameRegex = new RegExp('^[a-zA-Z ]*$');
    const emailRegex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');

    function randomNumberInRange(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const Register = async (e) => {
        handleLoadingToggle(true);
        setMsg(''); setSurnameMsg(''); setConfPassMsg(''); setLastNameMsg(''); setEmailMsg(''); setPassMsg('');
        e.preventDefault();

        let errors = {};
        let formIsValid = true;
		
        if (!surname) {
          setSurnameMsg('Veuillez renseigner votre prénom.');
          formIsValid = false;
        } else {
          if (!nameRegex.test(surname)) {
            setSurnameMsg('Veuillez entrer un prénom valide.');
            formIsValid = false;
          }
        }
        if (!lastname) {
          setLastNameMsg('Veuillez entrer votre nom de famille.');
          formIsValid = false;
        } else {
          if (!nameRegex.test(lastname)) {
            setLastNameMsg('Veuillez saisir un nom de famille valide.');
            formIsValid = false;
          }
        }
        if (!email) {
          setEmailMsg('Veuillez renseigner votre email.');
          formIsValid = false;
        } else {
          if (!emailRegex.test(email)) {
            setEmailMsg('Veuillez saisir une adresse e-mail valide.');
            formIsValid = false;
          }
        }
        if (!password) {
          setPassMsg('Veuillez renseigner votre mot de passe.');
          formIsValid = false;
        }
        if (!confPassword) {
          setConfPassMsg('Veuillez renseigner la confirmation de mot de passe.');
          formIsValid = false;
        } else {
          if (confPassword !== password) {
            setConfPassMsg('Le mot de passe et le mot de passe de confirmation ne correspondent pas.');
            formIsValid = false;
          }
        }
		if (!password) {
			setPassMsg('Veuillez renseigner votre mot de passe.');
			formIsValid = false;
		} else if (!passwordRegex.test(password)) {
			setPassMsg('Le mot de passe doit contenir au moins 8 caractères dont un caractère spécial et un chiffre.');
			formIsValid = false;
		}
		if (!answer) {
			setAnsMsg('Veuillez renseigner la réponse à la question posée.');
			formIsValid = false;
		} else {
		if (String(num1 + num2) !== answer.trim()) {
			setAnsMsg('Le résultat est incorrect, veuillez réessayer !');
			formIsValid = false;
			}
		}
		if (!acceptTerms) {
            setTermsMsg('Veuillez accepter les conditions générales d\'utilisation.');
            formIsValid = false;
        }
        if (!acceptPrivacy) {
            setPrivacyMsg('Veuillez accepter la politique de confidentialité.');
            formIsValid = false;
        }
		
        if (formIsValid == true) {
            try {
              const user = await axios.post( API_URL + '/auth/signup', {
                  surname: surname,
                  lastname: lastname,
                  company: company,
                  email: email,
                  password: password,
                  confPassword: confPassword
                });
                setSurName('');
                setLastName('');
                setCompany('');
                setEmail('');
                setPassword('');
                setConfPassword('');
                setAnswer('');
                handleLoadingToggle(false);
                history.push({
                  pathname: "/login",
                  state: user.data.msg,
                });
            } catch (error) {
              handleLoadingToggle(false);
                if (error.response) {
                  if (error.response.data.status === 403) 
                  {
                    var errorType = error.response.data.type;
                    var errorMessages = error.response.data;

                    var errorMessage =""
                    if(typeof errorMessages.surnameMsg == 'string' && errorMessages.surnameMsg !== '') {
                        errorMessage = errorMessages.surnameMsg;
                    }
                    if(typeof errorMessages.emailMsg == 'string' && errorMessages.emailMsg !== '') {
                        errorMessage =  (errorMessages.emailMsg);
                    }
                    if(typeof errorMessages.passMsg == 'string' && errorMessages.passMsg !== '') {
                        errorMessage = (errorMessages.passMsg);
                    }
                    if(typeof errorMessages.confPassMsg == 'string' && errorMessages.confPassMsg !== '') {
                        errorMessage = (errorMessages.confPassMsg);
                    }
                    setAlertMessage(errorMessage);
                    setSeverity('error');
                    setIsAlert(true);
                  } else {
                    setAlertMessage(error.response.data.msg);
                    setSeverity('error');
                    setIsAlert(true);
                  }
                }
            }

        } else {
          handleLoadingToggle(false);
          return false;
        }

    }
	
	const handleAcceptTermsChange = () => {
	setAcceptTerms(!acceptTerms);
	};

	const handleAcceptPrivacyChange = () => {
	setAcceptPrivacy(!acceptPrivacy);
	};

	function handleClick() {
		const newNum1 = randomNumberInRange(1, 20);
		const newNum2 = randomNumberInRange(1, 20);
		setNum(newNum1);
		setNum2(newNum2);
		setButtonText(newNum1 + ' + ' + newNum2);
	}


    useEffect(async () => {
      try {
        if (userToken !== undefined && userToken !== null && userToken !== '') {
          await axios.get(API_URL + '/users/get-auth-user/' + userToken, {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }).then(function (response) {
            setUserID(response.data.id);
            history.push("/dashboard");
          });
        } else {
          setUserToken(null);
          setUserID(null);
        }
      } catch (error) {
        console.log(error)
      }
    }, []);

  return (
    <form className={classes.root} onSubmit={Register}>
      <h1 className="login-title">Inscription</h1>

      {isAlert ? (
        <Box sx={{ width: '100%' }} >
          <Snackbar sx={{ width: '100%' }} spacing={2}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isAlert}
            autoHideDuration={5000}
            onClose={() => {
              setIsAlert(false);
              setAlertMessage('');
              setSeverity('');
            }}>
            <Alert
              sx={{ alignItems: 'center' }}
              severity={severity}
              action={
                <IconButton
                  sx={{ margin: '0px !important' }}
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setIsAlert(false);
                    setAlertMessage('');
                    setSeverity('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {alertMessage}
            </Alert>
          </Snackbar>
        </Box>
      ) : ''}
      
      {msg !== '' ? <p className={classes.error}>{msg}</p> : ''}      
      {successMsg !== '' ? <p className={classes.success}>{successMsg}</p> : ''}
      <TextField
        label="Prénom *"
        value={surname}
        onChange={e =>  { setSurName(e.target.value); setSurnameMsg(''); setMsg(''); } }
        variant="outlined"
      />
      {surnameMsg !== '' ? <p className={classes.error}>{surnameMsg}</p> : ''}
      <TextField
        label="Nom *"
        value={lastname}
        onChange={e => { setLastName(e.target.value);  setLastNameMsg(''); setMsg(''); } }
        variant="outlined"
      />
      {lastNameMsg !== '' ? <p className={classes.error}>{lastNameMsg}</p> : ''}
      <TextField
        label="Entreprise "
        value={company}
        onChange={e => setCompany(e.target.value)}
        variant="outlined"
      />
      <TextField
        label="Email *"
        value={email}
        onChange={(e) => { 
          setEmail(e.target.value.trim());   
          setEmailMsg(''); 
          setMsg(''); 
        }}
        variant="outlined"
      />
      {emailMsg !== '' ? <p className={classes.error}>{emailMsg}</p> : ''}
      <TextField
        label="Mot de passe *"
        type="password"
        value={password}
        onChange={e => { setPassword(e.target.value); setPassMsg(''); setMsg(''); } }
        autoComplete="new-password"
        variant="outlined"
      />
      {passMsg !== '' ? <p className={classes.error}>{passMsg}</p> : ''}
	  <Typography variant="caption" color="textSecondary">
		Minimum 8 caractères, dont un caractère spécial et un chiffre.
	</Typography>
      <TextField
        label="Confirmation du mot de passe *"
        type="password"
        value={confPassword}
        onChange={e => { setConfPassword(e.target.value); setConfPassMsg('');  setMsg(''); }}
        autoComplete="new-password"
        variant="outlined"
      />
      {confPassMsg !== '' ? <p className={classes.error}>{confPassMsg}</p> : ''}
      <div>
      <span>
        {buttonText} = ?
      </span>
      <Button type="button" color='secondary' onClick={handleClick} variant='contained' startIcon={<RefreshIcon />}>Rafraichir</Button>
      </div>
      <TextField
        label="Réponse *"
        value={answer}
        onChange={e => { setAnswer(e.target.value); setAnsMsg('');  setMsg(''); }}
        variant="outlined"
      />
      {ansMsg !== '' ? <p className={classes.error}>{ansMsg}</p> : ''}
	  	<div style={{ marginTop: '10px' }}>
        <label>
			<input
				type="checkbox"
				checked={acceptTerms}	
				onChange={e =>  { handleAcceptTermsChange(); setTermsMsg('');  } }
            />
             <span style={{ marginLeft: '8px' }}>J'accepte les <a href="https://faqguide.help/conditions-generales-d-utilisation/" target="_blank" rel="noopener noreferrer">conditions générales d'utilisation</a></span>
		</label>
		{termsMsg !== '' ? <p className={classes.error}>{termsMsg}</p> : ''}
      </div>
      <div style={{ marginTop: '10px' }}>
        <label>
           <input
			type="checkbox"
			checked={acceptPrivacy}
			onChange={e =>  { handleAcceptPrivacyChange(); setPrivacyMsg('');  } }
			/>
		 <span style={{ marginLeft: '8px' }}>J'accepte la <a href="https://faqguide.help/politique-de-confidentialite/" target="_blank" rel="noopener noreferrer">politique de confidentialité</a></span>
        </label>
		{privacyMsg !== '' ? <p className={classes.error}>{privacyMsg}</p> : ''}
      </div>
      <div>
        <Button type="submit" variant="contained" color="primary" className={classes.customBtn}>
          Inscription
        </Button>
      </div>
    </form>
        
    )
}

export default Register
