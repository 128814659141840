import React, { useState, useRef, useEffect, Suspense } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import {API_URL, SITE_PREFIX, FREE_FAQ_CATEGORY_LIMIT, FREE_FAQ_CATEGORY_QUESTION_LIMIT } 
    from '../../constants.js';
import { Grid, Button, TextField, Container, Box, Tab, Tabs } from '@material-ui/core';
 
import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

import QRCode from "react-qr-code";
import ReactToPrint from "react-to-print";

const FaqPDF = () => {
    /*const element = (
      <html>
        <body>
          <style>
            {`
            .heading4 {
              background: darkgreen;
              color: white;
            }
            pre {
              background-color: #eee;
              padding: 10px;
            }`}
          </style>
          <h1>Heading 1</h1>
          <h2 style={{ backgroundColor: 'pink' }}>Heading 2</h2> 
        </body>
      </html>
    );
*/  
        const componentRef = React.useRef(null);
        const onBeforeGetContentResolve = React.useRef(null);


        const [loading, setLoading] = React.useState(false);
        const [text, setText] = React.useState("old boring text");


        const handleAfterPrint = React.useCallback(() => {
            console.log("`onAfterPrint` called");
        }, []);

        const handleBeforePrint = React.useCallback(() => {
            console.log("`onBeforePrint` called");
        }, []);

        const reactToPrintContent = React.useCallback(() => {
            return componentRef.current;
        }, [componentRef.current]);

        const reactToPrintTrigger = React.useCallback(() => {
            return <button>Print using a Functional Component</button>;
        }, []);


      const handleOnBeforeGetContent = React.useCallback(() => {
        console.log("`onBeforeGetContent` called");
        setLoading(true);
        setText("Loading new text...");

        return new Promise((resolve) => {
          onBeforeGetContentResolve.current = resolve;

          setTimeout(() => {
            setLoading(false);
            setText("New, Updated Text!");
            resolve();
          }, 2000);
        });
      }, [setLoading, setText]);

    

    return ( <> 
    <ReactToPrint
        content={reactToPrintContent}
        documentTitle="AwesomeFileName"
        onAfterPrint={handleAfterPrint}
        onBeforeGetContent={handleOnBeforeGetContent}
        onBeforePrint={handleBeforePrint}
        removeAfterPrint
        trigger={reactToPrintTrigger}
      />
      <ComponentToPrint ref={componentRef} />
   </> );

}



const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} >
        <h1>Heading 1</h1>
        <h2>Heading 2</h2>
        <h3>Heading 3</h3>
        <h4>Heading 4</h4>
        <p>
          Paragraph with <strong>bold</strong>, <i>italic</i>, <u>underline</u>,
          <s>strikethrough</s>,
          <strong><u><s><i>and all of the above</i></s></u></strong>
        </p>
        <p>
          Paragraph with image and
          <a href="http://google.com">link</a>
        </p>
        <hr />
        <ul>
          <li>Unordered item</li>
          <li>Unordered item</li>
        </ul>
        <ol>
          <li>Ordered item</li>
          <li>Ordered item</li>
        </ol>
        <br /><br /><br /><br /><br />
        Text outside of any tags
        <table>
          <thead>
            <tr>
              <th>Column 1</th>
              <th>Column 2</th>
              <th>Column 3</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Foo</td>
              <td>Bar</td>
              <td>Foobar</td>
            </tr>
            <tr>
              <td colspan="2">Foo</td>
              <td>Bar</td>
            </tr>
            <tr>
              <td>Some longer thing</td>
              <td>Even more content than before!</td>
              <td>Even more content than before!</td>
            </tr>
          </tbody>
        </table>
    </div>
  );
});


export default FaqPDF