import React, { Suspense, useEffect, useCallback, useRef, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";

import Dashboard from "./components/Dashboard";
import ForgetPassword from "./components/ForgetPassword";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import Register from "./components/Register";
import VerifyAccount from "./components/VerifyAccount";
import UpdateProfile from "./components/UpdateProfile";
import Header from "./components/ResponsiveHeader/Header";
import Thankyou from "./components/Thankyou";
import FaqPDF from "./components/FaqsNew/FaqPDF";
import axios from "axios";
import { API_URL } from "./constants.js";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { Helmet, HelmetProvider } from 'react-helmet-async';

const useForceRerendering = () => {
  const [counter, setCounter] = React.useState(0);
  return () => setCounter(counter => counter + 1);
};

const CreateFaqs = React.lazy(() => import("./components/FaqsNew/CreateFaqs"));
const ImportFaqs = React.lazy(() => import("./components/FaqsNew/ImportFaqs"));
const EditFAQ = React.lazy(() => import("./components/FaqsNew/EditFaqs"));
const PreviewFAQ = React.lazy(() => import('./components/FaqsNew/PreviewFAQ'));


function App() {

  const [openPageLoading, setOpenPageLoading] = React.useState(false);
  const handleLoadingToggle = (value) => {
    setOpenPageLoading(value);
  };

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [pageTitle, setPageTitle ] = useState('FAQGUIDE - Votre FAQ en quelques clics');
  const [metaDescription, setMetaDescription ] = useState('FAQGUIDE - Votre FAQ en quelques clics');
  const [isIndex, setIsIndex ] = useState(0);

  useEffect(() => {
    
    if(urlParams.size == 1 && urlParams.get("slug") != null) {
      var slug = urlParams.get("slug");
      axios
      .get(API_URL + "/auth/faqs/preview/" + slug )
      .then((response) => {
        var html = response.data.faq.description;
        var text = html.replace(/(<([^>]+)>)/g, "");
        var title = response.data.faq.name;
        setPageTitle(title.trim());
        setMetaDescription(text.trim());

        var accessibilities = response.data.accessibilities;
        var accessibilitiesArray = Object.keys(accessibilities).map((key) => accessibilities[key]);
        if (accessibilitiesArray.length > 0) {
          setIsIndex(response.data.accessibilities.is_index);
        }
      }).catch((error) => {
        console.log(error);
      });
    }

    window.process = {
      ...window.process,
    };
  }, []);

  let forceUpdate = useForceRerendering();

  return (<>

    <HelmetProvider>

      <BrowserRouter>

        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={metaDescription} />
        </Helmet>

        <Switch>

          {/* Login page */}
          <Route exact path="/login">
            <Header key={`login-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Login handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>

          {/* FAQ PDF page */}
          <Route exact path="/faq-pdf">
            <FaqPDF />
          </Route>

          {/* Thank you page */}
          <Route exact path="/thank-you">
            <Header key={`home-header`} forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Thankyou handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>

          {/* Register page */}
          <Route path="/register">
            <Header key={`register-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Register handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>
          
          {/* Forgot Password page */}
          <Route exact path="/forget-password">
            <Header key={`forgot-password-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <ForgetPassword handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>

          {/* Verify Account Page */}
          <Route exact path="/account-verify">
            <Header key={`account-verify-header`} theme="light" useForcforceUpdate={forceUpdate} eRerendering handleLoadingToggle={handleLoadingToggle} />
            <VerifyAccount />
          </Route>

          {/* Create FAQ page */}
          <Route exact path="/faqs/create">
            <Header key={`faqs-create-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Suspense fallback={<div>Loading...</div>}>
              <CreateFaqs forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            </Suspense>
            <Footer />
          </Route>
          
          {/* Import FAQ page */}
          <Route exact path="/faqs/import">
            <Header key={`faqs-import-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Suspense fallback={<div>Loading...</div>}>
              <ImportFaqs forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            </Suspense>
            <Footer />
          </Route>

          {/* Edit FAQ page */}
          <Route exact path="/faqs/edit/:faqid">
            <Header key={`faqs-edit-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Suspense fallback={<div>Loading...</div>}>
              <EditFAQ forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            </Suspense>
            <Footer />
          </Route>

          {/* Home page */}
          <Route exact path="/faq">
            <Suspense fallback={<div>Loading...</div>}>
              
                <PreviewFAQ slug="" openPageLoading={openPageLoading} handleLoadingToggle={handleLoadingToggle}  />
              
            </Suspense>
          </Route>


          {/* Update Profile */}
          <Route exact path="/update-profile">
            <Header key={`update-profile-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <UpdateProfile handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>

          {/* My Account */}
          <Route exact path="/my-account">
            <Header key={`my-account-header`} theme="light" forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <UpdateProfile handleLoadingToggle={handleLoadingToggle} />
            <Footer />
          </Route>

          {/* Dashboard page */}
          <Route exact path="/dashboard">
            <Header key={`dashboard-header`} theme="light" forceUpdate={forceUpdate}
              handleLoadingToggle={handleLoadingToggle} />
            <Dashboard handleLoadingToggle={setOpenPageLoading} forceUpdate={forceUpdate} openPageLoading={openPageLoading} key="main-dashboard" />
            <Footer />
          </Route>

          {/* Home page */}
          <Route path="/">
            <Header key={`home-header`} forceUpdate={forceUpdate} handleLoadingToggle={handleLoadingToggle} />
            <Home />
            <Footer />
          </Route>

        </Switch>

        <div>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}
            open={openPageLoading}
          // onClick={() => { handleLoadingToggle(false) }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>

      </BrowserRouter>

    </HelmetProvider>


  </>);
}

export default App;
