import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import { API_URL, SUPER_ADMIN } from '../../constants.js';
import {
  AppBar, Toolbar, CssBaseline, useTheme, useMediaQuery,
  Box, Button, Grid
} from "@material-ui/core";
import { makeStyles } from 'tss-react/mui.js';
import DrawerComponent from "./DrawerComponent";
import FAQGUIDE_LOGO from '../../images/Logo-FAQGUIDE.svg';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import { createTheme } from "@material-ui/core/styles";
const theme = createTheme({});

const useStyles = makeStyles()((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(2),
    display: "flex",
    float: "right"
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    width: '100%',
    maxWidth: '230px'
  },
  stickyHeader: {
    flexGrow: '1',
    width: "100%",
    position: 'fixed',
    top: "0px",
    zIndex: "9"
  },
  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(5),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
  textConnect: {
    fontSize: "15px"
  },
  accountLink: {
    borderColor: 'transparent',
    cursor: 'pointer',
    justifyContent: 'center',
    paddingBottom: 'calc(0.5em - 1px)',
    paddingTop: 'calc(0.5em - 1px)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
	color: '#1b2854',
	fontSize: '15px',
	paddingLeft: '20px',
	paddingRight: '20px',
	'&:hover': {
    color: "#485fc7",
    },
  },
  btnSignUp: {
    background: '#1b2854',
	fontSize: '15px',
    '&:hover': {
      background: "#000",
    },
    borderColor: 'transparent',
    color: '#fff',
    '&:hover': {
      color: "#fff",
    },
    cursor: 'pointer',
    justifyContent: 'center',
    paddingBottom: 'calc(0.5em - 1px)',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: 'calc(0.5em - 1px)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    borderRadius: "6px"
  },
  btnSignIn: {
    borderColor: 'transparent',
    cursor: 'pointer',
    justifyContent: 'center',
    paddingBottom: 'calc(0.5em - 1px)',
    paddingTop: 'calc(0.5em - 1px)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
	color: '#1b2854',
	fontSize: '15px',
	paddingLeft: '20px',
	paddingRight: '20px',
	'&:hover': {
    color: "#485fc7",
    },
	'&:active': {
    color: "#485fc7",
    },
  },
  btnSignOut: {
    background: 'red !important',
	fontSize: '15px',
    ['&:hover $child']: {
      background: "red",
    },
    borderColor: 'transparent',
    color: '#fff',
    ['&:hover $child']: {
      color: "#fff",
    },
    cursor: 'pointer',
    justifyContent: 'center',
    paddingBottom: 'calc(0.5em - 1px)',
    paddingLeft: '1em',
    paddingRight: '1em',
    paddingTop: 'calc(0.5em - 1px)',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  iconArrow: {
    "fontSize": "1rem !important",
    "position": "relative",
    "top": "3px",
  },
}));

function Header({ forceUpdate, handleLoadingToggle }) {

  const history = useHistory();
  const color = '#fff';
  const theme = useTheme();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [faqItems, setFaqItems] = useState([]);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const [userID, setUserID] = useState(localStorage.getItem('user_id'));
  const isMobile = useMediaQuery('(max-width:1023px)');
  const [user, setUser] = useState([]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((!open) ? true : false);
  };

  const handleClose = (e) => {
    if (e.currentTarget.localName !== "ul") {
      const menu = document.getElementById("simple-menu-1").children[2];
      const menuBoundary = {
        left: menu.offsetLeft,
        top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
        right: menu.offsetLeft + menu.offsetHeight,
        bottom: menu.offsetTop + menu.offsetHeight
      };
      if (
        e.clientX >= menuBoundary.left &&
        e.clientX <= menuBoundary.right &&
        e.clientY <= menuBoundary.bottom &&
        e.clientY >= menuBoundary.top
      ) {
        return;
      }
    }
    setOpen(false);
  };
  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
    setOpen1((!open1) ? true : false);
  };
  const handleClose1 = (e) => {
    const menu2 = document.getElementById("simple-menu-2").children[2];
    const menuBoundary2 = {
      left: menu2.offsetLeft,
      top: e.currentTarget.offsetTop + e.currentTarget.offsetHeight,
      right: menu2.offsetLeft + menu2.offsetHeight,
      bottom: menu2.offsetTop + menu2.offsetHeight
    };
    if (
      e.clientX >= menuBoundary2.left &&
      e.clientX <= menuBoundary2.right &&
      e.clientY <= menuBoundary2.bottom &&
      e.clientY >= menuBoundary2.top
    ) {
      return;
    }
    setOpen1(false);
  }

  const refreshToken = async (e) => {
 

      if (userToken !== undefined && userToken !== null && userToken !== '') {
        try {
          const response = await axios.get(API_URL + '/users/get-auth-user/' + userToken, {
            headers: {
              Authorization: `Bearer ${userToken}`
            }
          }).then(function (response) {
            setUser(response.data);
            setIsLoggedIn(true);
            setIsLoggedOut(false);
          });

          } catch (error) {
            if (error.response) {
              history.push("/");
            }
          }
      } else {
        setUserToken(null);
        setUserID(null);
      }
    
  }

  // Login check Effect
  useEffect(() => {
    refreshToken();
  }, [forceUpdate]);

  const [subscribedFaq, setSubscribedFaq] = useState(0);
  // FAQ data
  useEffect(async () => {
    if (userToken !== undefined && userToken !== null && userID !== undefined && userID !== '') {
      axios.get(API_URL + '/faqs/sidebar-list', {
        headers: {
          Authorization: `Bearer ${userToken}`
        }, params: {
          userId: userID
        }
      }).then((response) => {
        setFaqItems(response.data)
      }).catch(() => {
        setFaqItems([]);
      });

      var result = await axios.get(API_URL + '/subscription/active/' + userID, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (typeof result.data.result != 'undefined') {
        if (result.data.result == 0) {
          setSubscribedFaq(1);
        } else {
          setSubscribedFaq(parseInt(result.data.result));
        }
      } else {
        setSubscribedFaq(1);
      }
    }


  }, [forceUpdate]);

  const Logout = async () => {
    try {
      await axios.delete(API_URL + '/auth/logout', {
        headers: {
          Authorization: `Bearer ${userToken}`
        }
      });
      setIsLoggedIn(false);
      setIsLoggedOut(true);
      setUserToken(null);
      setUserID(null);
      localStorage.clear('token');
      localStorage.clear('user_id');
      history.push("/login");
    } catch (error) {
    }
    handleLoadingToggle(false);
  }

  const { classes, cx } = useStyles();

  return (<>
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" component="nav" color="inherit">

        <CssBaseline />
        <Toolbar>

          <div>
            <img src={FAQGUIDE_LOGO} alt="logo-Faqguide" title="Logo-FAQGUIDE" className={cx(classes.logo)} onClick={() => {
              history.push('/dashboard');
            }} />
          </div>

          {isMobile ? (
            <DrawerComponent handleLoadingToggle={handleLoadingToggle} />
          ) : (

            <Grid item xs={12}>
              <div className={cx(classes.navlinks)}>
                <a className={cx(classes.btnSignIn)} href="https://faqguide.help/" aria-current="page">Notre solution</a>
                {!isLoggedIn ? (
                  <>
                    <Link to="/login" className={cx(classes.btnSignIn)}>
                      Se connecter
                    </Link>
                    <Link to="/register" className={cx(classes.btnSignUp)} >
                      Inscription
                    </Link>
                  </>
                ) : (<>

                  <a className={cx(classes.btnSignIn)} href="https://faqguide.help/aide-creation-FAQ" aria-current="page" target="_blank">Aide pour créer ma FAQ</a>
                  <Link to={`/dashboard`} className={cx(classes.accountLink)}>
                    Mes FAQ
                  </Link>

                  {/* <div className="menus-mini-drapdown"> Mes FAQ's<KeyboardArrowDownIcon fontSize="small" />
                    <div className="dropdown-content">

                      {(SUPER_ADMIN === parseInt(userID)) ? (<>
                        {faqItems.map((faq, index) => (<>
                          <React.Fragment key={`faqItems${faq.id}`}>
                            <a
                              href={`/faqs/edit/${faq.id}?id=${faq.id}&slug=${faq.slug}`}
                              onClick={forceUpdate}>
                              {faq.name}</a>
                          </React.Fragment>
                        </>))}
                      </>) : <>
                        {faqItems.slice(0, subscribedFaq).map((faq, index) =>
                          subscribedFaq > index ? (<>
                            <React.Fragment key={`faqItems${faq.id}`}>
                              <a
                                href={`/faqs/edit/${faq.id}?id=${faq.id}&slug=${faq.slug}`}
                                onClick={forceUpdate}>
                                {faq.name}</a>
                            </React.Fragment>
                          </>) : ''
                        )
                        }
                      </>}
                      <Link to="/faqs/create"> Create a new FAQ </Link>
                    </div>
                  </div> */}

                  <Link to={`/my-account?id=${user.id}`} className={cx(classes.accountLink)}>
                    Mon compte
                  </Link>
                  <Button
                    onClick={() => {
                      handleLoadingToggle(true);
                      Logout();
                    }}
                    size="small"
                    variant="contained"
                    color="secondary"
                    className={cx(classes.btnSignOut)}
                  >
                    Déconnexion
                  </Button></>)
                }

              </div>
            </Grid>
          )}
        </Toolbar>

      </AppBar>
    </Box>

  </>);
}



const MenuItems = (props) => {

  const [subscribedFaq, setSubscribedFaq] = useState(0);
  const [userToken, setUserToken] = useState(localStorage.getItem('token'));
  const [userID, setUserID] = useState(localStorage.getItem('user_id'));
  const [faqItems, setFaqItems] = useState([]);

  // FAQ data
  useEffect(async () => {
    if (userToken !== undefined && userToken !== null && userID !== undefined && userID !== '') {
      axios.get(API_URL + '/faqs/sidebar-list', {
        headers: {
          Authorization: `Bearer ${userToken}`
        }, params: {
          userId: userID
        }
      }).then((response) => {
        setFaqItems(response.data)
      }).catch(() => {
        setFaqItems([]);
      });

      var result = await axios.get(API_URL + '/subscription/active/' + userID, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (typeof result.data.result != 'undefined') {
        if (result.data.result == 0) {
          setSubscribedFaq(1);
        } else {
          setSubscribedFaq(parseInt(result.data.result));
        }
      } else {
        setSubscribedFaq(1);
      }
    }
  }, []);

  return (<React.Fragment>

  </React.Fragment>);

}

export default Header;